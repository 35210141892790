import type { SEGMENT_TRACK_EVENTS } from "~/constants/segment";

interface IIdentifyAuth {
  email: string;
  name: string;
  country: string;
  tenant: string;
  last_sign_method: TAuthProvider;
}

export interface ISegmentTracks {
  [SEGMENT_TRACK_EVENTS.SIGN_UP_STARTED]: any;
  [SEGMENT_TRACK_EVENTS.SIGN_UP_COMPLETED]: {
    sign_method: TAuthProvider;
  };
  [SEGMENT_TRACK_EVENTS.USER_SUCCESSFUL_LOGIN]: {
    sign_method: TAuthProvider;
  };
  [SEGMENT_TRACK_EVENTS.USER_FAILED_LOGIN]: {
    sign_method: TAuthProvider;
    reason: { error_code: string; message: string };
  };
  [SEGMENT_TRACK_EVENTS.HIGHLIGHT_PAGE_VISITED]: any;
  [SEGMENT_TRACK_EVENTS.CLICKS_ON_BANNERS_FROM_HOMEPAGE]: any;
  [SEGMENT_TRACK_EVENTS.CLICKS_ON_BANNERS_FROM_CATEGORIES]: any;
  [SEGMENT_TRACK_EVENTS.CLICKS_ON_BANNER_FROM_TEAMS]: any;
  [SEGMENT_TRACK_EVENTS.VISIT_FOLLOWING_PAGE]: any;
  [SEGMENT_TRACK_EVENTS.TEAM_FOLLOWED]: {
    team_id: string;
    team_name: string;
  };
  [SEGMENT_TRACK_EVENTS.CATEGORY_FOLLOWED]: {
    category_id: string;
    category_name: string;
  };
  [SEGMENT_TRACK_EVENTS.CLIPPING_PROCESS_STARTED]: {
    event_id: string;
  };
  [SEGMENT_TRACK_EVENTS.CLIP_CREATED]: {
    event_id: string;
  };
  [SEGMENT_TRACK_EVENTS.CLICKS_ON_LEAGUES]: any;
  [SEGMENT_TRACK_EVENTS.CLICKS_ON_SUB_CATEGORIES]: any;
  [SEGMENT_TRACK_EVENTS.EVENT_SHARE_TO_SOCIAL]: {
    event_id: string;
    method: TSharingProvider;
  };
  [SEGMENT_TRACK_EVENTS.CLIP_SHARE_TO_SOCIAL]: {
    event_id: string;
    clip_id: string;
    method: TSharingProvider;
  };
  [SEGMENT_TRACK_EVENTS.CLICKS_ON_TEAMS_PAGES]: any;
  [SEGMENT_TRACK_EVENTS.CLICKS_ON_ROUNDS]: any;
  [SEGMENT_TRACK_EVENTS.USER_REQUEST_RESET_PASSWORD]: any;
  [SEGMENT_TRACK_EVENTS.DOWNLOAD_CLIP_STARTED]: any;
  [SEGMENT_TRACK_EVENTS.BACK_TO_EVENT_FROM_HIGHLIGHT]: any;
  [SEGMENT_TRACK_EVENTS.CATEGORY_UNFOLLOWED]: any;
  [SEGMENT_TRACK_EVENTS.VISIT_MY_CLIPS_PAGE]: any;
  [SEGMENT_TRACK_EVENTS.DOWNLOAD_VIDEO_STARTED]: any;
  [SEGMENT_TRACK_EVENTS.USER_USED_ACCESS_CODE]: any;
  [SEGMENT_TRACK_EVENTS.SUBSCRIPTION_PURCHASE]: any;
  [SEGMENT_TRACK_EVENTS.DELETE_DEVICE]: any;
  [SEGMENT_TRACK_EVENTS.SUBSCRIPTION_CANCELED]: any;
  [SEGMENT_TRACK_EVENTS.USER_UPDATED_PROFILE]: any;
}

export interface ISegmentAnalytics {
  page: (path: string, params?: any) => void;
  reset: () => void;
  group: () => void;
  track: <T extends keyof ISegmentTracks>(event: T, params?: ISegmentTracks[T]) => void;
  identify: (userId: string, user: IIdentifyAuth) => void;
}

declare let window: Window & typeof globalThis & { analytics?: ISegmentAnalytics };

type warnMethod = "page" | "reset" | "group" | "track" | "identify";

export default function useSegment(): ISegmentAnalytics {
  const warn = (method: warnMethod, args: any[]): void => {
    console.warn(`[Segment]: 'analytics.${method}()' disabled since Segment tracking disabled or script does not exist`, {
      args,
    });
  };

  function track<T extends keyof ISegmentTracks>(event: T, params?: ISegmentTracks[T]) {
    const runtimeConfig = useRuntimeConfig();
    const tenant = runtimeConfig.public.APP_CUSTOMER.web_sdk.tenant;

    window.analytics?.track(event, Object.assign({}, params, { tenant }));
  }

  if (window.analytics) {
    return {
      track,
      page: window.analytics.page,
      reset: window.analytics.reset,
      group: window.analytics.group,
      identify: window.analytics.identify,
    };
  }

  return (
    window.analytics || {
      page: (...args: any[]) => warn("page", args),
      reset: (...args: any[]) => warn("reset", args),
      group: (...args: any[]) => warn("group", args),
      track: (...args: any[]) => warn("track", args),
      identify: (...args: any[]) => warn("identify", args),
    }
  );
}
