export const THEME_COLOR = {
  DARK: "dark",
  LIGHT: "light",
} as const;

export const DEVICE = {
  MOBILE: "mobile",
  TABLET: "tablet",
  DESKTOP: "desktop",
} as const;

export const SOCIAL_PROVIDER = {
  FACEBOOK: "facebook",
  APPLE: "apple",
  GOOGLE: "google",
  IFA: "ifa",
} as const;

export const AUTH_METHOD = {
  SIGN_UP: "signup",
  SIGN_IN: "signin",
} as const;
